import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEineWohnungInOesterreich = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet eine Wohnung in Österreich?" showCalc={false} />
            <Article>
                <p>
                    Eine eigene Wohnung bedeutet Sicherheit und Freiheit. Doch der Weg dorthin ist auch eine
                    Herausforderung. Die Kosten und die eigene finanzielle Lage werden beim Wohnungskauf oft
                    unterschätzt. Den Überblick über Maklerprovisionen, Kreditangebote und alle Nebenkosten zu bewahren,
                    ist ganz schön schwierig. In diesem Artikel soll es deshalb darum gehen, was es wirklich kostet,
                    sich den Traum von einer eigenen Wohnung zu erfüllen und welche laufenden Kosten dich als
                    zukünftige*r Wohnungseigentümer*in erwarten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Fixkosten beim Wohnungskauf</h2>
                <p>
                    Neben dem Kaufpreis der Wohnung musst du 7 % des Kaufpreises für Grunderwerbsteuer,
                    Grundbucheintragung und Kosten für Rechtsanwälte und Notare zum Wohnungspreis hinzurechnen.
                    Einschließlich der Maklerprovision machen die Nebenkosten damit 11,2% des Kaufpreises aus. Im
                    Folgenden haben wir alle Kostenpunkte zusammengefasst:
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Finanzierungsgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h3>Maklerprovision</h3>
                <p>
                    Mit 3,6% des Kaufpreises fällt die Maklerprovision ganz schön hoch an. Wenn du dir diese sparen
                    möchtest, kannst du selbst nach provisionsfreien Wohnungen suchen. Diese sind aber oft weniger
                    hochwertig, da Makler*innen das bessere Sortiment haben. Außerdem bieten seriöse Makler*innen dir
                    eine gute Beratung und helfen dir auf dem Weg der Abwicklung, da sie mit Notaren, Anwälten und
                    häufig auch mit Finanzexperten vernetzt sind.
                </p>
                <p>
                    <strong>Tipp:</strong> Vielleicht verkauft auch jemand in deinem Familien- oder Bekanntenkreis eine
                    hochwertige Wohnung. Frühzeitiges Nachfragen und Erkundigen schadet jedenfalls nicht, denn
                    vielleicht kannst du dir auf diesem Weg die Maklerprovision sparen.
                </p>

                <h3>Grunderwerbsteuer</h3>
                <p>
                    Die Grunderwerbsteuer ist beim Immobilienkauf nicht zu umgehen. Diese beträgt in Österreich ca. 3,5%
                    des Kaufpreises. Sonderregelungen gibt es lediglich für Unternehmen. In diesen Fällen sind 0,5% zu
                    zahlen.
                </p>

                <h3>Eintragung ins Grundbuch</h3>
                <p>
                    Wenn du dein Wohneigentumsrecht in das Grundbuch eintragen lässt, musst du ca. 1,1% des Kaufpreises
                    dafür bezahlen. Falls du einen Hypothekarkredit aufgenommen hast, trägt die Bank außerdem dein
                    Pfandrecht auch im Grundbuch ein, wofür du noch zusätzliche 1,2% zahlen musst.
                </p>

                <h3>Notar- bzw. Anwaltskosten</h3>
                <p>
                    Das Erstgespräch bei Notaren oder Anwälten kostet erstmal nichts. Allerdings zahlst du die Verträge
                    und die Begleitung der Abwicklung beim Kauf einer Wohnung. Diese Kosten orientieren sich am Wert der
                    Immobilie. Der Kaufvertrag wird von Notaren oder Rechtsanwälten errichtet. Wichtige Faktoren wie
                    beispielsweise die Wohnungsausstattung.
                </p>

                <h3>Kosten für Sachverständige</h3>
                <p>
                    Bei der Feststellung des Werts einer Liegenschaft ist es gut, auf zuverlässige beeidete
                    Sachverständige zurückzugreifen. Diese verlangen jedoch etwa 500 bis 1000 Euro pro Schätzung. Bei
                    teureren Immobilien oder umfassenden Beschreibungen verlangen sie oft noch mehr. Mit diesen Kosten
                    musst du gegebenenfalls also auch noch rechnen.
                </p>
                <p>Bekannte Mängel der Wohnung, Übergabetermin und Zahlungsfrist sollten dabei geklärt werden.</p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kostenlose Beratung holen
                    </a>
                </p>
                <hr />

                <h2>Was beeinflusst den Preis einer Wohnung?</h2>
                <p>
                    Vor allem zwei Faktoren sind es, die den Kaufpreis deiner zukünftigen Wohnung beeinflussen: Ihr
                    Zustand und ihre Lage. Beginnen wir mit der Lage und sehen uns an, mit welchen Quadratmeterpreisen
                    in unterschiedlichen Teilen Österreichs zu rechnen ist.
                </p>

                <h3>Die Lage</h3>
                <p>
                    Die Quadratmeterpreise variieren abhängig von der Lage der Wohnung. Doch nicht nur deshalb ist die
                    Lage ein wichtiges Kriterium bei der Wohnungswahl. Sie spielt auch dann eine entscheidende Rolle,
                    wenn die Immobilie als Investition mit Wertsteigerung betrachtet wird. In fünf bis zehn Jahren kann
                    eine Wohnung durch ihre Lage durchaus an Wert gewinnen. Deshalb sollte man sich auch über die
                    zukünftige Stadtplanung in der jeweiligen Umgebung informieren (ist z.B. der Bau neuer
                    U-Bahn-Stationen geplant?).
                </p>

                <h4>Wien</h4>
                <p>
                    In Wien zahlt man aktuell je nach Größe in der Inneren Stadt zwischen 7.000 und 10.000 € pro
                    Quadratmeter. In den restlichen inneren und äußeren Stadtbezirken Wiens liegt der Preis pro
                    Quadratmeter bei ca. 2.700 bis 6.500 €. Im zweiten Bezirk kostet eine neue Eigentumswohnung
                    beispielsweise ca. 3.692,10 €, eine gebrauchte 2.733,93 pro Quadratmeter.
                </p>

                <h4>St. Pölten</h4>
                <p>
                    In der Niederösterreichischen Landeshauptstadt ist im Falle eines Erstbezugs mit einem
                    Quadratmeterpreis von ca. 2.242,75 € zu rechnen. Bei gebrauchten Wohnungen zahlst du etwa 1.531,42 €
                    pro Quadratmeter.
                </p>

                <h4>Linz</h4>
                <p>
                    Mit etwa 2.804,97 € pro Quadratmeter ist eine neue Wohnung in Linz etwas teurer als in
                    Niederösterreich. Das gilt auch für bereits benutzte Eigentumswohnungen: Hier kommen ca. 1.729,04 €
                    auf dich zu.
                </p>

                <h4>Salzburg</h4>
                <p>
                    Ein besonders teures Pflaster ist Salzburg. Bei Erstbezug zahlst du für eine Wohnung hier stolze
                    4.216,09 € pro Quadratmeter. Mit Quadratmeterkosten von 2.989,39 € liegt auch der Preis gebrauchter
                    Wohnungen über dem Niveau der Bundeshauptstadt.
                </p>

                <h4>Innsbruck</h4>
                <p>
                    Ebenfalls teurer als in Wien ist der Wohnraum in Innsbruck: 4.110,06 € kostet dich der Quadratmeter
                    einer neuen Wohnung hier. Auch der Quadratmeterpreis gebrauchter Wohnungen liegt mit 2.733,31 € nur
                    knapp unter dem Wiener Preisniveau.
                </p>

                <h4>Bregenz</h4>
                <p>
                    Günstiger fallen die Preise in Bregenz aus: Ca. 3.815,90 € pro Quadratmeter kostet eine neue
                    Eigentumswohnung, eine gebrauchte in etwa 2.276,85 €.
                </p>

                <h4>Graz</h4>
                <p>
                    Ebenfalls billiger ist der Wohnraum in Graz. Etwa 3.093,99 € pro Quadratmeter musst du in der
                    steirischen Hauptstadt im Falle eines Erstbezugs ausgeben. Entscheidest du dich für eine gebrauchte
                    Wohnung, so sind es lediglich 1.688,00 €.
                </p>

                <h4>Klagenfurt</h4>
                <p>
                    Noch ein wenig günstiger steigst du in Klagenfurt aus: Der Preis für neue Eigentumswohnungen liegt
                    hier bei ca. 2.360,08 € pro Quadratmeter. Gebrauchte kosten dich etwa 1.434,38 €.
                </p>

                <h4>Eisenstadt</h4>
                <p>
                    Am günstigsten ist der Wohnraum derzeit in Eisenstadt. Hier liegt der Preis einer neuen Wohnung bei
                    2.133,42 € pro Quadratmeter. Handelt es sich um eine gebrauchte, so zahlst du mit einem
                    Quadratmeterpreis von 1.488,64 € nur geringfügig mehr als in Klagenfurt.
                </p>

                <h3>Zustand</h3>
                <p>
                    Der Zustand der Wohnung beeinflusst ihren Preis natürlich auch. Renovierungsbedürftige Wohnungen
                    bekommst du zwar für einen günstigeren Preis, doch musst du hier gut überlegen, ob sich dieser
                    angesichts der Renovierungskosten und des Aufwands insgesamt wirklich lohnt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungstermin vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Bei der Wohnungsbesichtigung solltest du folgende Punkte überprüfen:</h2>
                <ul>
                    <li>Zustand der Zimmer, Wände, Böden, Fenster, Leitungen und Rohre</li>
                    <li>Anschlüsse: Strom, Kabel, Satellit, Waschmaschine, Geschirrspüler, Herd (Strom/Gas?)</li>
                    <li>Elektronik</li>
                    <li>Sind alle angegebenen Daten korrekt? (Quadratmeterzahl etc.)</li>
                </ul>
                <p>Neben dem Zustand der Wohnung solltest du auch den Zustand des Gebäudes erfragen:</p>
                <ul>
                    <li>
                        Sind Investitionen geplant und wieviel Kapital steht den Eigentümer*innen eigentlich zur
                        Verfügung?
                    </li>
                    <li>Welche Sanierungen und Renovierungen wurden im Laufe der letzten Jahre getätigt?</li>
                    <li>Muss in nächster Zeit etwas im Gebäude renoviert werden?</li>
                    <li>Steht eine Liftreparatur an?</li>
                    <li>Ist das Dach in Ordnung?</li>
                    <li>Wird es in Zukunft einen Ausbau des Dachgeschoßes geben?</li>
                    <li>Wurde eine thermische Sanierung geplant?</li>
                    <li>In welchem Zustand befindet sich der Keller?</li>
                    <li>Wie hoch ist der Reperaturfond?</li>
                </ul>
                <p>
                    Alle diese Dinge findest du heraus, wenn dir die Protokolle der Wohungseigentümerversammlungen
                    gezeigt werden. Üblicherweise finden in jedem Haus einmal jährlich Eigentümerversammlungen statt, in
                    denen man gemeinsam anstehende Renovierungen und Änderungen bespricht. Hier solltest du dir
                    zumindest die Protokolle der letzten drei Jahren ansehen.
                </p>
                <hr />

                <h2>Laufende Kosten als Wohnungseigentümer*in</h2>
                <p>Betriebskosten sind laufende Instandhaltungs- und Verwaltungskosten. Dazu gehören generell:</p>
                <ul>
                    <li>Verwaltungskosten</li>
                    <li>Hausbetreuungskosten (entweder durch externe Hausbetreuung oder Hausbesorger*innen)</li>
                    <li>Wasser- und Abwassergebühren (inkl. Wartungsgebühren)</li>
                    <li>
                        Kosten für die Eichung, Wartung und das Ablesen von Messvorrichtungen zur Verbrauchsermittlung
                    </li>
                    <li>Müllabfuhr</li>
                    <li>Kanalräumungskosten</li>
                    <li>Kosten für die Schädlingsbekämpfung</li>
                    <li>Beleuchtungskosten des allgemeinen Teils des Gebäudes</li>
                    <li>Rauchfangkehrerkosten</li>
                    <li>
                        Versicherungen wie z.B. Feuerversicherung, Haftpflichtversicherung,
                        Leitungswasserschädenversicherung und andere Versicherungen gegen Glasbruch und Sturmschäden
                        (Wenn die Mehrheit der Mieter zustimmt)
                    </li>
                </ul>
                <p>
                    Als Eigentümer*in kommen aber noch weitere Kosten auf dich zu, wie z.B. Ausgaben für
                    Gemeinschaftsanlagen, Rücklagen, Annuitätenzahlungen, also die jährlichen Zinsen- und Tilgungsraten
                    für Investitionen, etc.
                </p>
                <hr />

                <h2>Fazit: Lass dich nicht abschrecken, aber informiere dich gut!</h2>
                <p>
                    Beim Wohnungskauf fallen viele Kosten an, die anfangs einschüchternd wirken können. Doch wenn du gut
                    im voraus planst, dich professionell beraten lässt und nicht voreilig handelst, kann sich der Kauf
                    einer Wohnung durchaus lohnen. Eine eigene Wohnung bietet dir als Eigentümerin nicht nur absolute
                    Sicherheit und Freiheit im Vergleich zu Mietwohnungen, sondern ist auch ein hervorragendes
                    Investment. Dir gleich zu Beginn professionelle Unterstützung für den gesamten Prozess zu suchen,
                    spart dir später viel Zeit, Geld und Nerven.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEineWohnungInOesterreich"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEineWohnungInOesterreich"}
                heading={"Was kostet eine Wohnung in Österreich?"}
            />
        </Layout>
    );
};

export default WasKostetEineWohnungInOesterreich;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-eine-wohnung-in-oesterreich", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
